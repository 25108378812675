import React from "react";
import error from "../component/assets/img/404.webp";

import { Helmet } from "react-helmet";

const Thanks = () => {
  return (
    <div className="container pb-5">
      <Helmet>
        <title>Thank-you</title>
      </Helmet>
  

      <section className="login-main-wrapper">
      <div className="main-container">
        <div className="login-process">
          <div className="login-main-container">
            <div className="thankyou-wrapper">
              <h1><img src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png" alt="thanks" /></h1>
              <p>THANK YOU OUR TEAM WILL GET BACK TO YOU SOON</p>
              <a href="/">Back to home</a>
              <div className="clr"></div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
    </section>


     
    </div>
  );
};

export default Thanks;
