import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import "../component/assets/css/course.css";
import banner from "../component/assets/img/imresizer-1686256112147.webp";
import { Card, Placeholder } from "react-bootstrap";
const Course = () => {
  const MAX_LENGTH = 220;
  const [course, setCourse] = useState([]);
  const [categorycourse, setcategory] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    let isMountered = true;
    setTimeout(() => {
      axios.get("/api/course").then((res) => {
        if (isMountered) {
          if (res.data.status === 200) {
            //console.log(res.data.product)
            setCourse(res.data.product);
            //setcategory(res.data.catagery);
            setisLoading(false);
          }
        }
      });
    });
    return () => {
      isMountered = false;
    };
  }, []);

  useEffect(() => {
    axios.get("/api/category").then((res) => {
      if (res.data.status === 200) {
        //console.log(res.data.category)
        setcategory(res.data.category);
      }
    });
  }, []);

  // axios.get(`/api/course?name=${data}`).then(res=>{
  const temp = (data) => {
    // Button click onchange color change
    for (let i = 0; i < categorycourse.length; i++) {
      console.log(document.getElementById(data));
      console.log(categorycourse[i].id.toString());
      if (categorycourse[i].id.toString() === data) {
        document.getElementById(categorycourse[i].id).style.backgroundColor =
          "#ffb03b";
      } else {
        document.getElementById(categorycourse[i].id).style.backgroundColor =
          "#fff";
      }
    }

    // end
    setisLoading(true);
    //console.log(data)
    let isMountered = true;
    setTimeout(() => {
      axios.get(`/api/course?name=${data}`).then((res) => {
        if (isMountered) {
          if (res.data.status === 200) {
            console.log(res.data.product);
            setCourse(res.data.product);
            setisLoading(false);
          }
        }
      });
    });
    return () => {
      isMountered = false;
    };
  };
  return (
    <>
      <Helmet>
        <title>All Course</title>
        <meta name="description" content="Free Web tutorials" />
        <meta name="keywords" content="HTML, CSS, JavaScript" />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
          integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="/js/js/main.js" type="text/javascript" />
      </Helmet>

      <section
        className="page_breadcrumbs ds color parallax about_us section_padding_top"
        style={{ backgroundImage: `url(${banner})`, height: "404px" }}
      >
        <div
          className="container"
          style={{ zIndex: "1", position: "relative" }}
        >
          <div className="row">
            <div className="col-sm-12">
              <h2 className="heading text-light pb-2">Our Courses</h2>
              <ol className="breadcrumb highlightlinks">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active fw-bold text-white"> Courses </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="ovelay_1"></div>
      </section>

      <div className="container pt-5">
        <div>
          <ul
            className="nav nav-lb-tab mb-6  bg-secondary rounded-3 row g-1 border border-0"
            id="pills-tab"
            role="tablist"
          >
            {categorycourse.map((cat, i) => (
              <li
                key={i}
                className="filter nav-item col-auto p-1"
                data-role="button"
                data-filter=".website-developer"
                id="developer"
                style={{ margin: "8px" }}
              >
                <button
                  id={cat.id}
                  onClick={() => temp(`${cat.id}`)}
                  className="w-100 border-0 p-2 px-3 text-dark fw-bold rounded"
                  style={{ backgroundColor: "#fff", fontSize: "13px" }}
                >
                  {cat.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          {isLoading ? (
            <>
              <div className="pb-4">
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>

                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>

                  <div className="col-lg-4 mb-3">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder
                        xs={12}
                        style={{ height: "320px", borderRadius: "10px" }}
                      />{" "}
                    </Placeholder>
                  </div>
                </div>
              </div>
            </>
          ) : (
            course.map((corse, i) => (
              <div className="col-lg-4 mb-4" key={i}>
                <div className="cardCourses">
                  <img
                    src={`http://aismcourses.com/aism/public/${corse.image}`}
                    alt={corse.name}
                    title={corse.name}
                  />
                  <div className="info">
                    <h4 className="pb-2 fw-bold">{corse.name}</h4>
                    <div
                      className="coursecontent"
                      dangerouslySetInnerHTML={{
                        __html: corse.description.substring(0, MAX_LENGTH),
                      }}
                    ></div>

                    <div>
                      <a
                        className="book-a-table-btn fw-normal text-light"
                        href={`/our-courses/${corse.category_id}/${corse.slug}`}
                        style={{
                          background: "#ffb03b",
                          borderRadius: "30px",
                        }}
                      >
                        Read More <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Course;
