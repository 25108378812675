import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Placement = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
          
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  const [gallery, setgallery] = useState([]);
  useEffect(() => {
  
    setTimeout(() => {
      axios.get("/api/gallery").then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.gallery);
            setgallery(res.data.gallery);
            //setcategory(res.data.catagery);
        }
      });
    });
   
  }, []);

  return (
  
    <>
   <Carousel responsive={responsive}>
   {gallery.map((cat, i) => (
        <div key={i} className="p-2">
          <img src={`http://aismdeoband.com/aism/public/${cat.image}`} alt={cat.title} className="w-100 placement"  />
        </div>
        
      ))}
</Carousel>
     
     </>

  );
};

export default Placement;
