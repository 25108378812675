import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import "../component/assets/css/course.css";
import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";
const Courseview = () => {
  const { slug } = useParams();
  const { category } = useParams();
  const [product, setProduct] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isRelated, setrelated] = useState([]);
  useEffect(() => {
    let isMountered = true;
    setTimeout(() => {
      axios.get(`/api/view-product/${category}/${slug}`).then((res) => {
        if (isMountered) {
          if (res.data.status === 200) {
            setProduct(res.data.product_data.product);
            //console.log(res.data.product_data.product);
            setisLoading(false);
          }
        }
      });
    });
    return () => {
      isMountered = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMountered = true;
    setTimeout(() => {
      axios.get(`/api/related/${category}`).then((res) => {
        if (isMountered) {
          if (res.data.status === 200) {
            // setProduct(res.data.product_data.product);
            setrelated(res.data.product);
            setisLoading(false);
          }
        }
      });
    });
    return () => {
      isMountered = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={12} style={{ height: "404px" }} />
          </Placeholder>
          <div className="container pt-3">
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} style={{ height: "174px" }} />{" "}
              <Placeholder xs={3} style={{ height: "174px" }} />{" "}
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} style={{ height: "174px" }} />{" "}
              <Placeholder xs={3} style={{ height: "174px" }} />{" "}
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} style={{ height: "174px" }} />{" "}
              <Placeholder xs={3} style={{ height: "174px" }} />{" "}
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} style={{ height: "174px" }} />{" "}
              <Placeholder xs={3} style={{ height: "174px" }} />{" "}
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} style={{ height: "174px" }} />{" "}
              <Placeholder xs={3} style={{ height: "174px" }} />{" "}
            </Placeholder>
          </div>
        </>
      ) : (
        product.map((corse, i) => (
          <div key={i}>
            <Helmet>
              <title>{corse.meta_title}</title>
              <meta name="description" content={corse.meta_keyword} />
              <meta name="keywords" content={corse.meta_description} />
              <script
                src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
                integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
                crossorigin="anonymous"
                referrerpolicy="no-referrer"
              />
              <script
                src="/js/bootstrap.bundle.min.js"
                type="text/javascript"
              />
              <script serc="/js/js/swiper/swiper-bundle.min.js" />
              <script src="/js/glightbox/js/glightbox.min.js" />
              <script src="/js/isotope-layout/isotope.pkgd.min.js"></script>
              <script src="/js/js/main.js"></script>
            </Helmet>
            <section
              className="page_breadcrumbs ds color parallax about_us section_padding_top"
              style={{
                backgroundImage: `url(http://aismcourses.com/aism/public/${corse.image})`,
                height: "404px",
              }}
            >
              <div
                className="container"
                style={{ zIndex: "1", position: "relative" }}
              >
                <div className="row pt-4">
                  <div className="col-sm-12 text-center">
                    <h1 className="heading text-light">{corse.name}</h1>
                  </div>
                </div>
              </div>
              <div className="ovelay_1"></div>
            </section>
            <div className="container pt-5">
              <div className="row g-0">
                <div className="col-lg-9 px-lg-5 mb-3">
                  <div className="course-overview">
                    <div className="bg-secondary">
                      <ol
                        className="breadcrumb highlightlinks p-3"
                        style={{ fontSize: "12px" }}
                      >
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/our-courses">All courses</a>
                        </li>
                        <li className="active fw-bold text-white">
                          {corse.name}
                        </li>
                      </ol>
                    </div>
                    <img
                      src={`http://aismcourses.com/aism/public/${corse.image}`}
                      alt={corse.name}
                      width={"100%"}
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: corse.description }}
                    ></div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <h3 className="pb-3 fs-5 text-dark">Related Course</h3>
                  {isLoading ? (
                    <></>
                  ) : (
                    isRelated.map((related, i) => (
                      <div className="pb-0" key={i}>
                        <a
                          href={`/our-courses/${related.category_id}/${related.slug}`}
                        >
                          <div
                            className="imgdiv"
                            style={{
                              backgroundImage: `url(http://aismcourses.com/aism/public/${related.image})`,
                            }}
                          ></div>
                          <div className="related px-4">
                            <p className="pt-2">{related.name}</p>
                          </div>
                        </a>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default Courseview;
