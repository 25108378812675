import React from "react";
import { Helmet } from "react-helmet";
import banner from "../component/assets/img/imresizer-1686256688224.webp";
import about_us from "../component/assets/img/about_us.webp";
const About = () => {
  return (
    <>
      <Helmet>
        <title>About-Us</title>
        <meta name="description" content="Aismdeoband Aism deoband" />
        <meta name="keywords" content="Aismdeoband Aism deoband" />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
          integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />

        <script src="/js/js/main.js" type="text/javascript" />
      </Helmet>
      <section
        className="page_breadcrumbs ds color parallax about_us section_padding_top"
        style={{ backgroundImage: `url(${banner})`, height: "404px" }}
      >
        <div
          className="container"
          style={{ zIndex: "1", position: "relative" }}
        >
          <div className="row">
            <div className="col-sm-12">
              <h2 className="heading text-light pb-2">About Us</h2>
              <ol className="breadcrumb highlightlinks">
                <li>
                  <a href="/"> Home </a>
                </li>
                <li className="active fw-bold text-white">About Us</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="ovelay_1"></div>
      </section>
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-6 col-sm-6 text-center mb-3">
            <div className="margin-top30 about-us-img">
              <img
                src={about_us}
                alt="about"
                title="About Aism Deoband"
                className="img-responsive w-100"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="tittle_left pb-4">
              <h2 className="text-uppercase">About Aism Deoband</h2>
            </div>

            <div style={{ textAlign: "justify" }}>
              <p>
                The Aism Deoband is Safety Course Training Institute In India
                which is providing education and learning about health and
                safety to give an excellent career to aspirants. Our safety
                training and environmental management consultancy programs are
                making particular places safer and more sustainable.
              </p>
              <p>
                At the Aism Deoband, protection is our principal ardor to make
                the place of work more secure for each individual. We provide a
                complete variety of in-house, services to the economic world. We
                offer all of the essential protection education and answers to
                the organization. It's due to the professional group that we
                will offer protection measures and teach each individual. As a
                whole lot as we cherish protection, it'd be incorrect if we did
                now no longer point out our humans and teamwork which can be our
                maximum precious assets.
              </p>

              <p>
                While selecting the Aism Deoband, you'll revel in not anything
                but our enduring dedication to enhancing your protection
                knowledge. We try to provide customer’s satisfactory in
                magnificence offerings with our relentless devotion and
                difficult work.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2>Director’s Message</h2>
          <p>
            The Aism Deoband acknowledges the significance of teamwork with
            businesses, each huge, medium, and small, and is inquisitive about
            partnering with forward-thinking corporations to beautify the value
            to our customers.
          </p>
          <p>
            We are continually seeking out the quality and the brightest experts
            with the willpower to work and a wonderful attitude. Our personnel
            are our largest property and every worker is a part of our family.
            We are devoted to sharing and care every other, and play a position
            as a part of an auditor through expert delivery as an accomplice
            resource for excessive overall performance to success. So, in case
            you need excessive overall performance and boom pleasant and
            productivity, we're here. If you're thinking about becoming a member
            of the Aism Deoband, we inspire you to come & communicate with us
            and would like to explain the interesting provider and worthwhile
            patron pleasant missions that we support. We desire to listen from
            you and welcome you to our developing organization. We can make
            contributions to persistent development thru diverse pleasant
            equipment on this aggressive gain in which we connect.
          </p>
          <p>
            Thank you for giving us your precious time to read this message. We
            stay up for operating with you. If, I can be of any help in the
            future, please contact me immediately at [email id]
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
