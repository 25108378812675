import React from "react";
import error from "../component/assets/img/404.webp";
import { Helmet } from "react-helmet";

const Errorpage = () => {
  return (
    <div className="container pb-5">
      <Helmet>
        <title>404 - Page Note Found</title>
      </Helmet>
      <a href="/">
        <img
          src={error}
          alt="404 page Note Found"
          className="w-100 pb-3"
          style={{
            height: "500px",
            objectFit: "scale-down",
            marginTop: "140px",
          }}
        />
      </a>
      <div className="text-center">
        <a href="/" class="book-a-table-btn border-0  fw-bold rounded ms-0 p-3">
          Go Back
        </a>
      </div>
    </div>
  );
};

export default Errorpage;
