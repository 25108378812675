import React, { useEffect, useState } from "react";
import Slider from "./inc/Slider";
import { Helmet } from "react-helmet";
import axios from "axios";
import ourkey from "../component/assets/img/imresizer-1686259414193.webp";
import img from "../component/assets/img/WhatsApp_Image_2023-05-28_at_12.52.34_AM-removebg-preview.png";
import vision from "../component/assets/img/imresizer-1686260334769.webp";
import mission from "../component/assets/img/imresizer-1686260874385.webp";
import ourvalue from "../component/assets/img/imresizer-1686338033986.webp";
import group from "../component/assets/img/imresizer-1686338033986.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Placement from "./inc/Placement";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Component from '../component/assets/img/aism-deoband.webp'
const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const options1 = {
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      600: {
        items: 3,
        margin: 20,
      },
      1000: {
        items: 4,
        margin: 20,
      },
    },
  };
  const testimonials = {
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      600: {
        items: 3,
        margin: 0,
      },
      1000: {
        items: 4,
        margin: 0,
      },
    },
  };
  const MAX_LENGTH = 220;
  const [course, setCourse] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    let isMountered = true;
    setTimeout(() => {
      axios.get("/api/course-home").then((res) => {
        if (isMountered) {
          if (res.data.status === 200) {
            //console.log(res.data.product)
            setCourse(res.data.product);
            //setcategory(res.data.catagery);
            setisLoading(false);
          }
        }
      });
    });
    return () => {
      isMountered = false;
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Aism Deoband Best Safety Course Training Institute In India |
        </title>
        <meta
          name="description"
          content="Aism Deoband aismdeoband Safety Course Training Institute In India offers Best Safety Training in India & plays a lead role in environmental management consultancy programs"
        />
        <meta
          name="keywords"
          content="Aism deoband aismdeoband best safety training in India, Safety Course Training Institute In India, safety training, National Safety Course,International Diploma, IOSH MANAGING SAFELY,IOSH WORKING SAFELY"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
          integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script serc="/js/js/swiper/swiper-bundle.min.js" />
        <script src="/js/glightbox/js/glightbox.min.js" />
        <script src="/js/isotope-layout/isotope.pkgd.min.js"></script>
        <script src="/js/js/main.js"></script>
      </Helmet>

      <Slider />
      <main id="main">
        <section
          id="about"
          className="about"
          style={{
            backgroundImage: `url(${Component})`,
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            backgroundPosition: "right",
            overflow: "hidden",
          }}
        >
          <div>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card">
                    <p>Aism Deoband</p>
                    <h2 className="heading">
                      HSE Consultancy and Training Services
                    </h2>
                    <p>
                      Aism Deoband has achieved phenomenal success in delivering
                      quality Health, Safety, Environmental Training, and
                      Consultancy Services to make the world a safer and
                      sustainable place to live.
                    </p>

                    <div className="text-center">
                      <a
                        href="/about"
                        className="book-a-table-btn border-0 p-3 text-dark fw-bold rounded"
                      >
                        {" "}
                        Know more about HSE Services
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="why-us" className="why-us bg-secondary">
          <div className="container">
            <div className="section-title text-start">
              <h2 className="heading text-white">Our Courses</h2>
            </div>

            <Carousel responsive={responsive}>
              {isLoading ? (
                <>loding</>
              ) : (
                course.map((corse, i) => (
                  <div key={i} className="p-3">
                    <div className="cardCourses">
                      <img
                        src={`http://aismcourses.com/aism/public/${corse.image}`}
                        alt={corse.name}
                        title={corse.name}
                      />
                      <div className="info">
                        <h4 className="pb-2 fw-bold">{corse.name}</h4>

                        <div
                          className="coursecontent"
                          dangerouslySetInnerHTML={{
                            __html: corse.description.substring(0, MAX_LENGTH),
                          }}
                        ></div>

                        <div>
                          <a
                            href={`our-courses/${corse.category_id}/${corse.slug}`}
                            className="book-a-table-btn fw-normal text-light"
                            style={{
                              background: "#ffb03b",
                              borderRadius: "30px",
                            }}
                          >
                            Read More{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </Carousel>
          </div>

          <div className="text-center pt-4">
            <a
              href="our-courses"
              className="book-a-table-btn border-0  fw-bold rounded ms-0 p-3"
            >
              view all courses
            </a>
          </div>
        </section>

        <section id="menu" className="menu">
          <div className="container-fluid">
            <div className="row g-1 justify-content-center">
              <div className="col-lg-6 text-end mb-4">
                <img
                  src={group}
                  alt="AISM World Group"
                  className="w-100 rounded"
                />
              </div>
              <div className="col-lg-4 p-lg-5 mb-4">
                <div className="pt-lg-5">
                  <p>AISM World Group</p>
                  <h2 className="title-heading-left md-text-align-center  pb-3 heading">
                    Corporate Courses
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    At AISM DEOBAND, we understand the critical importance of
                    maintaining a safe work environment for your employees. Our
                    comprehensive corporate training programs are designed to
                    equip your workforce with the knowledge and skills necessary
                    to identify and mitigate workplace hazards, reduce
                    accidents, and foster a culture of safety throughout your
                    organization.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-md-10">
                <h4 className="pb-2">
                  Why Choose Our Corporate Training Programs?
                </h4>
                <ol>
                  <li className="pb-2">
                    Tailored Training Solutions: We recognize that each
                    organization has unique safety requirements. Our corporate
                    training programs are customized to address the specific
                    needs and challenges of your industry, ensuring that the
                    training is relevant and practical for your workforce. We
                    work closely with your team to assess your organization's
                    safety goals, operational environment, and regulatory
                    requirements to develop a training program that meets your
                    specific needs.
                  </li>
                  <li className="pb-2">
                    Experienced and Knowledgeable Instructors: Our instructors
                    are experienced safety professionals who possess in-depth
                    knowledge of industry best practices, regulatory compliance,
                    and practical safety applications. They bring real-world
                    expertise to the training sessions, engaging participants
                    with relevant examples, case studies, and interactive
                    exercises that enhance learning and application of safety
                    principles.
                  </li>
                  <li className="pb-2">
                    Comprehensive Course Offerings: Our corporate training
                    programs cover a wide range of safety topics and
                    disciplines. Whether your organization requires training in
                    general safety awareness, hazard identification, emergency
                    response, occupational health, or specialized safety
                    programs for specific industries, we have the expertise and
                    resources to deliver the training your employees need.
                  </li>
                  <li className="pb-2">
                    Interactive and Engaging Training Methods: We believe that
                    active participation leads to effective learning. Our
                    corporate training programs incorporate interactive and
                    engaging training methods, such as group discussions,
                    hands-on exercises, practical simulations, and real-life
                    case studies. This ensures that participants actively engage
                    with the material, exchange ideas, and apply their knowledge
                    in a practical context.
                  </li>
                  <li className="pb-2">
                    Compliance with Regulations and Standards: Our corporate
                    training programs are designed to meet or exceed industry
                    regulations and standards. We stay up-to-date with the
                    latest safety guidelines and regulations, ensuring that our
                    training programs align with current requirements. By
                    choosing our training programs, you can be confident that
                    your organization is meeting its regulatory compliance
                    obligations.
                  </li>
                  <li className="pb-2">
                    Flexible Training Delivery: We understand the need for
                    flexibility in training delivery to accommodate the busy
                    schedules of your employees. Our corporate training programs
                    offer flexible delivery options, including on-site training
                    at your facility, virtual training sessions, or a blended
                    approach combining in-person and online training. This
                    flexibility allows your employees to access the training
                    conveniently, minimizing disruptions to workflow and
                    maximizing participation.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-secondary">
          <div className="container">
            <h2 className="title-heading-left pb-4 text-light heading">
              POPULAR COURSE
            </h2>

            <OwlCarousel
              className="owl-theme"
              loop={false}
              dots={false}
              nav={false}
              {...options1}
            >
              <div className="item">
                <div className="card-course item-card card-block">
                  <img
                    src="https://www.aismgroup.com/assets/cork/images/new-banner/cqi.png"
                    alt="Lead Auditor Course"
                    className="mb-2"
                  />
                  <h4 className="card-title text-right pb-2 pt-2">
                  Lead Auditor Course
                  </h4>
                  <p className="card-text">
                  ISO-9001:2015 QUALITY MANAGEMENT SYSTEM (QMS)
ISO-14001:2015 ENVIRONMENTAL MANAGEMENT SYSTEM (EMS)
ISO-45001:2015 OCCUPATIONAL HEALTH & SAFETY MANAGEMENT SYSTEM (OHMS)
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-course item-card card-block">
                  <img
                    src="https://www.aismgroup.com/assets/cork/images/new-banner/iosh.png"
                    alt="IOSH MANAGING SAFELY IOSH WORKING SAFELY"
                    className="mb-2"
                  />
                  <h4 className="card-title text-right pb-2 pt-2">IOSH</h4>
                  <p className="card-text">
                    IOSH MANAGING SAFELY IOSH WORKING SAFELY
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-course item-card card-block">
                  <img
                    src={img}
                    alt="National Safety Course"
                    className="mb-2"
                  />
                  <h4 className="card-title text-right pb-2 pt-2">
                    National Safety Course
                  </h4>
                  <p className="card-text">
                    MATER DIPLOMA COURSES POST GRADUATE DIPLOMA COURSES ADVANCE
                    DIPLOMA COURSES DIPLOMA COURSES ONE YEAR'S FIRE SAFETY
                    MANAGEMENT & INDUSTRIAL SAFETY MANAGEMENT COURSE.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-course item-card card-block">
                  <img
                    src="https://www.aismgroup.com/assets/cork/images/new-banner/othm.png"
                    alt="International Diploma"
                    className="mb-2"
                  />
                  <h4 className="card-title text-right pb-2 pt-2">
                    International Diploma
                  </h4>
                  <p className="card-text">
                    OTHM LEVEL 6- DIPLOMA OHS OTHM LEVEL 7- DIPLOMA SML OTHM
                    LEVEL 7- DIPLOMA PM EUROPEAN SAFETY COUNSIL LEVEL 7
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>

        <section id="specials" className="specials">
          <div className="container">
            <div className="section-title pb-lg-5">
              <h2 className="pb-lg-3 heading">OUR QUALITY POLICY</h2>
              <p>
                The AISM group Safety Course Training Institute In India is
                devoted to providing great training and education and objectives
                at being the main schooling issuer of technical publications via
                chronic development of its scholastic capacity and effectiveness
                of the great control system. The Quality coverage is
                communicated and understood by all personnel in the institute
                and is reviewed for persistent development.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      data-bs-toggle="tab"
                      href="#tab-1"
                    >
                      Vision
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                      Mission
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                      Our Core Value
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="tab-content">
                  <div className="tab-pane active show" id="tab-1">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Vision</h3>
                        <p style={{ textAlign: "justify" }}>
                          To gain popularity through consulting, auditing,
                          training, and qualifying specialists and company
                          groups via innovation and to develop alongside them in
                          creativity.
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={vision}
                          alt="vision"
                          className="img-fluid rounded"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-2">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Mission</h3>
                        <p>
                        Our mission is simple yet crucial: to ensure that every construction worker returns home safely at the end of the day. We strive to promote a safety-first mindset within your organization, helping you achieve compliance with regulations, reduce accidents, minimize risks, and foster a culture of safety that permeates every level of your company.
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={mission}
                          alt="Mission"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-3">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3 className="fw-bold">Our Services</h3>
                            <h6 className="fw-bold">Safety Program Development</h6>
                        <p style={{ textAlign: "justify" }}>
                        We create customized safety programs tailored to your construction projects, ensuring compliance with regulatory requirements while addressing the specific hazards and risks associated with your work environment.
                        </p>
                        <h6 className="fw-bold">Safety Consulting</h6>
                        <p style={{ textAlign: "justify" }}>
                        Our experienced safety consultants provide expert guidance and support to help you navigate complex safety challenges. From risk assessments and hazard identification to safety audits and inspections, we offer a comprehensive range of consulting services to enhance your safety performance.
                          </p>
                          <h6 className="fw-bold">Training and Workshops</h6>
                          <p>We offer a wide range of safety training programs and workshops designed to equip your workforce with the knowledge and skills needed to work safely and efficiently. Our training covers topics such as fall protection, hazard communication, equipment operation, and more.</p>
                          <h6>Incident Investigation and Analysis</h6>
                          <p>In the unfortunate event of a safety incident, our team conducts thorough investigations to determine the root causes and identify opportunities for improvement. We provide detailed reports and recommendations to help you prevent similar incidents in the future.</p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={ourvalue}
                          alt="Our Core Value"
                          className="img-fluid"
                          style={{ objectFit: "cover", height: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ background: "#ffb03b" }}>
          <div className="container">
            <h2 className="title-heading-left pb-4 heading">Placement</h2>
            <Placement />
          </div>
        </section>
        <section className="">
          <div className="container">
            <h2 className="title-heading-left pb-4 heading">Testimonials</h2>
          </div>
          <div className="container-fluid">
            <OwlCarousel
              className="owl-theme test"
              loop={true}
              dots={false}
              nav={false}
              center={true}
              URLhashListener={true}
              {...testimonials}
            >
              <div className="item">
                <div
                  className="card border-light card-accent-left p-0"
                  data-mh="testimonials-slider"
                  style={{ height: "362px" }}
                >
                  <div className="card-body p-19 p-md-20 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://lh3.googleusercontent.com/a-/AD_cMMR2moauN2h726IVdVlvQsbgzTXO7zocMcT68xsYeA=w36-h36-p-rp-mo-br100"
                        alt="Md Kaif"
                        width="50"
                        className="rounded-circle"
                        style={{ width: "50px" }}
                      />
                      <div className="ml-9">
                        <div className="text-black font-weight-medium fs-5 lh-5">
                          Md Kaif
                        </div>
                        <div className="text-muted fs-3 lh-4">Aismdeoband</div>
                      </div>
                    </div>
                    <blockquote className="my-14">
                      <p>
                        “Very healthy environment with cooperative teachers and
                        highly experienced trainers for each trainings( first
                        aid, fire safety, working at height, scaffold, rigger
                        training). Best institute for both individual and
                        corporate trainings.(IHSM )institute of health and
                        saftey management ...”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="card border-light card-accent-left p-0"
                  data-mh="testimonials-slider"
                  style={{ height: "362px" }}
                >
                  <div className="card-body p-19 p-md-20 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://lh3.googleusercontent.com/a/AAcHTtfDQzZJYLq-hFLypdQWLFTQYaUtgnNF0xtR-1zy=w36-h36-p-rp-mo-br100"
                        alt="AFTAB ALAM"
                        width="50"
                        className="rounded-circle"
                        style={{ width: "50px" }}
                      />
                      <div className="ml-9">
                        <div className="text-black font-weight-medium fs-5 lh-5">
                          AFTAB ALAM
                        </div>
                        <div className="text-muted fs-3 lh-4">Aismdeoband</div>
                      </div>
                    </div>
                    <blockquote className="my-14">
                      <p>
                        “IHSM team is very professional and supportive team. I
                        am completely satisfied with is safety institutes.”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="card border-light card-accent-left p-0"
                  data-mh="testimonials-slider"
                  style={{ height: "362px" }}
                >
                  <div className="card-body p-19 p-md-20 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://lh3.googleusercontent.com/a/AAcHTteOmektkYd8L5vma2JALOO8twEKXaYG1fGS7kvG=w36-h36-p-rp-mo-br100"
                        alt="Ehtesam Alam"
                        width="50"
                        className="rounded-circle"
                        style={{ width: "50px" }}
                      />
                      <div className="ml-9">
                        <div className="text-black font-weight-medium fs-5 lh-5">
                          Ehtesam Alam
                        </div>
                        <div className="text-muted fs-3 lh-4">Aismdeoband</div>
                      </div>
                    </div>
                    <blockquote className="my-14">
                      <p>
                        IHSM is a best institute of Health and Safety
                        Management. Trainers of this institute are very
                        knowledgeable, using case studies and story telling to
                        clear our concept about the topics.
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="card border-light card-accent-left p-0"
                  data-mh="testimonials-slider"
                  style={{ height: "362px" }}
                >
                  <div className="card-body p-19 p-md-20 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://lh3.googleusercontent.com/a-/AD_cMMR5VRaJoNlIDINUoUOH6NcRMUmwVBxGGEruCN1vxA=w36-h36-p-rp-mo-br100"
                        alt="Idle FIEND"
                        width="50"
                        className="rounded-circle"
                        style={{ width: "50px" }}
                      />
                      <div className="ml-9">
                        <div className="text-black font-weight-medium fs-5 lh-5">
                          Idle FIEND
                        </div>
                        <div className="text-muted fs-3 lh-4">Aismdeoband</div>
                      </div>
                    </div>
                    <blockquote className="my-14">
                      <p>
                        “This is the best institute for safety courses. The
                        teaching staff are very helpful in nature.”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>
        <section className="bg-secondary">
          <div className="container p-2">
            <div className="row">
              <div className="four col-md-3">
                <div className="counter-box colored">
                  <i className="bi bi-hand-thumbs-up-fill"></i>
                  <span className="counter">2147</span>
                  <p>Happy Students</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box">
                  <i className="bi bi-people"></i>
                  <span className="counter">3275</span>
                  <p>Registered Members</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box">
                  <i className="bi bi-book-fill"></i>

                  <span className="counter">60</span>
                  <p>Available Curse</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box">
                  <i className="bi bi-person-check-fill"></i>
                  <span className="counter">1563</span>
                  <p>Saved Trees</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="events" className="events">
          <div className="container">
            <div className="events-slider swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="row event-item">
                    <div className="col-lg-6">
                      <img
                        src={ourkey}
                        className="img-fluid rounded"
                        alt="Our key features"
                      />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                      <h3>Our key</h3>
                      <div className="price">
                        <p>
                          <span>features</span>
                        </p>
                      </div>
                      <ul>
                        <li>
                          <i className="bi bi-check-circle"></i> Ullamco laboris
                          nisi Our safety training in India services is
                          available at a very genuine cost with an ideal mixture
                          of health, protection, and environmental
                          trainers/specialists and a devoted customer support
                          team. Our purpose is to offer health & security
                          courses that allow our customers to maximize
                          productiveness and decrease twist of fate and coverage
                          fees thru the Health and Safety Management System's
                          powerful software.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
