import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import jwtDecode from "jwt-decode";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import registerimg from "../../assets/img/register.webp";
const Register = () => {
  const navigate = useNavigate();
  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    error_list: [],
  });
  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  function handleCallbackResponse(response) {
    // console.log(response.credential);
    var userObject = jwtDecode(response.credential);

    const data = {
      name: userObject.name,
      email: userObject.email,
      password: userObject.sub,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/register`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          navigate("/");
        } else {
          setRegister({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
          swal(
            "An account is already registered with your email address Please log in"
          );
        }
      });
    });
  }

  useEffect(() => {
    /*global google*/
    window.google.accounts.id.initialize({
      client_id:
        "635975820630-av4q71fleb5hk4n1i6sifq1uk1rmcccu.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signIndiv"),
      { thems: "outline", size: "large" }
    );
    google.accounts.id.prompt();
    // eslint-disable-next-line
  }, []);

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: registerInput.name,
      email: registerInput.email,
      password: registerInput.password,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/register`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          navigate("/");
        } else {
          setRegister({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
        }
      });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Sign Up Aism Deoband </title>
      </Helmet>

      <div
        style={{
          background: "#fff",
          paddingTop: "180px",
          paddingBottom: "100px",
        }}
      >
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-5 mb-2">
              <div className="card  pt-5 pb-5 px-4">
                <form onSubmit={registerSubmit}>
                  <FloatingLabel label="Full Name" className="pt-3">
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={registerInput.name}
                      placeholder="Enter Your Full Name"
                    />
                  </FloatingLabel>
                  <span className="text-danger ">
                    {registerInput.error_list.name}
                  </span>
                  <FloatingLabel label="Email" className="pt-3">
                    <Form.Control
                      type="email"
                      onChange={handleInput}
                      name="email"
                      value={registerInput.email}
                      placeholder="Enter Your Full Name"
                    />
                  </FloatingLabel>

                  <span className="text-danger">
                    {registerInput.error_list.email}
                  </span>
                  <FloatingLabel label="Password" className="pt-3">
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={registerInput.password}
                      placeholder="Enter password"
                    />
                  </FloatingLabel>
                  <span className="text-danger mb-3">
                    {registerInput.error_list.password}
                  </span>
                  <Button
                    variant="primary"
                    type="submit"
                    className="fw-bold w-100 mt-3"
                  >
                    <i className="bi bi-box-arrow-in-right"></i> Sign up
                  </Button>
                </form>

                <div className="text-center">
                  <div className="or mb-2 mt-4">OR</div>
                </div>

                <div className="text-center">
                  <div
                    id="signIndiv"
                    style={{ textAlign: "-webkit-center" }}
                  ></div>
                </div>
              </div>

              <div className="card pt-2 pb-2 px-4 text-center">
                <div>
                  Have an account?{" "}
                  <a href="/login" className="text-primary">
                    Log in
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={registerimg}
                alt="sign up"
                width={100}
                height={100}
                className="w-100"
                style={{ objectFit: "cover", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
