
// import "bootstrap/dist/js/bootstrap.bundle.js";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './component/assets/vendor/bootstrap/css/bootstrap.css';

import './component/assets/vendor/animate.css/animate.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './component/assets/css/style.css';
import './component/assets/css/thank-you.css';
import './component/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './component/assets/vendor/boxicons/css/boxicons.min.css';
import './component/assets/vendor/glightbox/css/glightbox.min.css';
import './component/assets/vendor/swiper/swiper-bundle.min.css';
import Navbar from "./component/inc/Navbar";
import Footer from "./component/inc/Footer";
import axios from 'axios';

import Home from "./component/Home";
import About from "./component/About";
import Course from "./component/Course";
import Contact from "./component/inc/Contact";
import Courseview from "./component/Courseview";
import Errorpage from "./component/Errorpage";
import Register from "./component/inc/auth/Register";
import Login from "./component/inc/auth/Login";
import Thanks from "./component/Thanks";

/*https://aismdeoband.com/aism */
axios.defaults.baseURL = "https://aismcourses.com/aism";
axios.defaults.headers.post['Content-Type']= 'application/json';
axios.defaults.headers.post['Accept']= 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
const token = localStorage.getItem('auth_token');
config.headers.Authorization = token ? `Bearer ${token}` : '';
return config;
})

function App() {
  return<BrowserRouter>
  <Navbar/>
  <Routes>
     <Route path='/' element={<Home />}/>
     <Route path='/about' element={<About />}/>
     <Route path="our-courses" element={<Course/>}/>
     <Route path="our-courses/:category/:slug" element={<Courseview/>}/>
     <Route path="/contact" element={<Contact/>}/>
     <Route path="/register" element={<Register/>}/>
     <Route path="/login" element={<Login/>}/>
     <Route path="/thank-you" element={<Thanks/>}/>
    
     <Route path="*" Component={Errorpage} />
  </Routes>
<Footer/>
  </BrowserRouter>;
}
export default App; 
