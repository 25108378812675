import React, { useEffect } from 'react'
import img from '../assets/img/WhatsApp_Image_2023-05-28_at_12.52.34_AM-removebg-preview.png';
import Form from "./Form";
import { useLocation } from 'react-router-dom';

const Navbar = () => { 
  const location = useLocation();
  useEffect(()=>
    { 
        if(location.pathname === '/about'){
          
        }
    
},[location])
  return (
   <>




    <section id="topbar" className="d-flex align-items-center fixed-top topbar-transparent bg-secondary">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-center justify-content-lg-start">
        <a href="tel:+91-7505979566"><i className="bi bi-phone d-flex align-items-center"><span>+91-7505979566</span></i></a>
        <a href="mailto:aismdeoband@gmail.com"><i className="bi bi-envelope-at ms-4 d-none d-lg-flex align-items-center"><span>aismdeoband@gmail.com</span></i></a> 
      </div>
    </section>
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <div className="logo me-auto">
          <a href="/">
          <img src={img} width="220" className="img-fluid" alt='AISM-World-Group' style={{objectFit:'cover', height:'65px'}} />
          </a>
        </div>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li><a className="nav-link scrollto active" href="/">Home</a></li>
            
            <li className="dropdown nav-link">
              <a href="/our-courses"><span>Lead Auditor Courses</span><i className="bi bi-chevron-down">
                  </i>
                    </a>
              <ul>
                <li className='nav-link'><a href="/our-courses/1/ohsas-45001-lead-auditor">OHSAS 45001 lead auditor </a></li>
                <li className='nav-link'><a href="/our-courses/1/qms-iso-9001-lead-auditor">QMS ISO 9001 Lead Auditor</a></li>
                <li className='nav-link'><a href="/our-courses/1/iso-45001-2018-irca-lead-auditor">ISO 45001:2018 IRCA Lead Auditor</a></li> 
              </ul>
            </li>

        
            <li className="dropdown">
              <a href="/our-courses"
                ><span>IOSH Courses</span> <i className="bi bi-chevron-down">
                    </i></a>
              <ul>
                <li><a href="/our-courses/2/iosh-managing-system">IOSH Managing system </a></li>
                <li><a href="/our-courses/2/iosh-working-safety">IOSH working safety</a></li>
              </ul>
            </li>


            <li className="dropdown">
              <a href="/our-courses"><span>Diploma Courses</span> 
                <i className="bi bi-chevron-down">
                    </i></a>
              <ul>
                <li><a href="/our-courses/3/cirtificate-courses">Certificates Courses</a></li>
                <li><a href="/our-courses/3/diploma-in-industrial-safety">Diploma in Industrial Safety</a></li>
                <li><a href="/our-courses/3/diploma-in-fire-safety">Diploma in Fire Safety</a></li>
                <li><a href="/our-courses">Certificate in Scarffolding Compitency</a></li>
                <li><a href="/our-courses">Diploma in Environmental Safety</a></li>
                <li><a href="/our-courses">Diploma in Construction Safety</a></li>
                <li><a href="/our-courses/3/diploma-in-food-safety">Diploma in Food Safety</a></li>
                <li><a href="/our-courses">FIRST AID TRANING</a></li>

              </ul>
            </li>
            <li><a className="nav-link scrollto" href="/our-courses">All  Courses</a></li>
            <li><a className="nav-link scrollto" href="/about">About</a></li>
            <li><a className="nav-link scrollto" href="/Contact">Contact</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
   
<Form/>
        {/* <a href="#book-a-table" className="book-a-table-btn scrollto"
          data-bs-toggle="modal" data-bs-target="#exampleModal">Enquiry Now</a> */}
        
      </div>
    </header>
   </>
  )
}

export default Navbar
