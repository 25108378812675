import React from "react";
const Footer = () => {
  return (
    <>
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget company_widget wow fadeInLeft"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    MozAnimationName: "fadeInLeft",
                  }}
                >
                  <div className="f-title f_600 t_color f_size_18">
                    Get in Touch
                  </div>
                  <p style={{ textAlign: "justify", fontSize: "15px" }}>
                    The AISM group is Safety Course Training Institute In India
                    which is providing education and learning about health and
                    safety to give an excellent career to aspirants. Our safety
                    training and environmental management consultancy programs
                    are making particular places safer and more sustainable.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 px-lg-5">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    MozAnimationName: "fadeInLeft",
                  }}
                >
                  <div className="f-title f_600 t_color f_size_18">
                    Our Course
                  </div>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="/our-courses">All Course</a>
                    </li>
                    <li>
                      <a href="/our-courses/1/ohsas-45001-lead-auditor">
                        Lead Auditor Courses
                      </a>
                    </li>
                    <li>
                      <a href="/our-courses/2/iosh-managing-system">
                        IOSH Courses
                      </a>
                    </li>
                    <li>
                      <a href="/our-courses/3/cirtificate-courses">
                        Diploma Courses
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    MozAnimationName: "fadeInLeft",
                  }}
                >
                  <div className="f-title f_600 t_color f_size_18">
                    Quick Links
                  </div>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                    <li>
                      <a href="/login">Login</a>
                    </li>
                    <li>
                      <a href="/register">Register</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget social-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.8s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.8s",
                    MozAnimationName: "fadeInLeft",
                  }}
                >
                  <div className="f-title f_600 t_color f_size_18">
                    Team Solutions
                  </div>
                  <div className="f_social_icon mb-4">
                    <a
                      className="btn btn-square btn-outline-light rounded-circle me-2"
                      href="https://x.com/AismDeoband?t=kZ-fJeEZQLcQKFEeDjvZ0Q&s=09"
                      style={{ background: "#55acee", color: "#fff" }}
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a
                      className="btn btn-square btn-outline-light rounded-circle me-2"
                      href="https://www.facebook.com/monis.ranaji"
                      style={{ background: "#3b5998", color: "#fff" }}
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      className="btn btn-square btn-outline-light rounded-circle me-2"
                      href="/"
                      style={{ background: "#ed302f", color: "#fff" }}
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                    <a
                      className="btn btn-square btn-outline-light rounded-circle me-2"
                      href="https://www.linkedin.com/in/aism-deoband-808973265?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      style={{ background: "#0082ca", color: "#fff" }}
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <a
                      className="btn btn-square btn-outline-light rounded-circle me-2"
                      href="https://www.instagram.com/aismdeoband/?igshid=MWZjMTM2ODFkZg%3D%3D"
                      style={{ background: "#ca005d", color: "#fff" }}
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </div>

                  <div style={{ color: "#be2026", fontSize: "18px" }}>
                    <strong>Main Branch Address</strong>
                  </div>
                  <p>
                    G-7 1 st Floor, Abul Fazal Part - II <br />
                    Jamia Nagar, New Delhi - 110025
                  </p>
                  <hr style={{ color: "#be2026" }} />
                  <div style={{ color: "#be2026", fontSize: "18px" }}>
                    <strong> Corporate Branch</strong>
                  </div>
                  <p>
                    Manglour Chowki, Deoband, Saharanpur <br />
                    uttar pradesh - 247001
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one"></div>
            <div className="footer_bg_two"></div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-7">
                <p className="mb-0 f_400">
                  © cakecounter Inc.. 2023 All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a aria-label="Chat on WhatsApp" href="https://wa.me/+917505979566" target="blank">
      <img alt="Chat on WhatsApp" src="https://aismdeoband.com/aism/public/WhatsAppButtonGreenMedium.png" style={{position:'fixed',bottom:'15px',right:'2px',height:'35px',zIndex:'2'}} />
    </a>

    </>
  );
};

export default Footer;
