import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import registerimg from "../../assets/img/login.webp";
import jwtDecode from "jwt-decode";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, Setemail] = useState("");
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const [isAuth,setIsAuth] = useState(false)
  function handleCallbackResponse(response) {
    var userObject = jwtDecode(response.credential);
    Setemail(userObject.email)
    setPassword(userObject.sub)
    setIsAuth(true)
  }
useEffect(()=>{
    if(isAuth){
        loginSubmit()
    }
    // eslint-disable-next-line
},[isAuth]);

  useEffect(() => {
    /*global google*/
    window.google.accounts.id.initialize({
      client_id:
      "635975820630-av4q71fleb5hk4n1i6sifq1uk1rmcccu.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signIndiv"),
      { thems: "outline", size: "large" }
    );
    google.accounts.id.prompt();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const [logininput, setLogin] = useState({
    error_list: [],
  });
  
  // const handleInput = (e) => {
  //   e.persist();
  //   setLogin({ ...logininput, [e.target.name]: e.target.value });
  // };
  
  const loginSubmit = () => {
  const data = {
      email:email,
      password:password,
  };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          navigate("/");
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else {
          setLogin({ ...logininput, error_list: res.data.validation_errors });
        }
      });
    });
  };
  
  return (
    <div
      style={{
        background: "#fff",
        paddingTop: "180px",
        paddingBottom: "100px",
      }}>
         <Helmet>
        <title>Log-In</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12 mb-2">
            <form className="card px-3" onSubmit={loginSubmit}>
              <TextField
                label="Email"
                multiline
                maxRows={4}
                fullWidth
                value={email}
                onChange={(e) => {
                    Setemail(e.target.value);
                  }}
                sx={{
                  bgcolor: "login.input",
                  color: "login.mainText",
                  borderRadius: "15px",
                  " & .MuiInputBase-root": {
                    borderRadius: "15px",
                  },
                  "& label.Mui-focused": {
                    color: "login.labelText",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "login.labelText",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "login.labelText",
                    },
                    "&:hover fieldset": {
                      borderColor: "login.labelText",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "login.labelText",
                    },
                  },
                }}
              />
<span className="text-danger mb-3">{logininput.error_list.email}</span>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClick}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                required
                fullWidth
                value={password}
                id="password"
                label="Password"
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                variant="outlined"
                sx={{
                  bgcolor: "login.input",
                  color: "login.mainText",
                  borderRadius: "15px",
                  " & .MuiInputBase-root": {
                    borderRadius: "15px",
                  },
                  "& label.Mui-focused": {
                    color: "login.labelText",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "login.labelText",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "login.labelText",
                    },
                    "&:hover fieldset": {
                      borderColor: "login.labelText",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "login.labelText",
                    },
                  },
                }}
              />
              <span className="text-danger mb-3">{logininput.error_list.password}</span>
                         <Button
                    variant="primary"
                    type="submit"
                    className="fw-bold w-100 mt-3">
                    <i className="bi bi-box-arrow-in-right"></i> Log in
                  </Button>
            </form> 

            <div className="card pt-2 pb-2 px-4 text-center">
                <div>
                Don't have an account? <a href="/register" className="text-primary">Sign up</a>
                </div>
              </div>

            <hr/>
             <div className="text-center pb-3">OR</div>
            <div id="signIndiv" style={{ textAlign: "-webkit-center" }}></div>

          </div>
          <div className="col-lg-5 col-12 mb-2">
            <img
              src={registerimg}
              alt="sign-up"
              width={100}
              height={100}
              className="w-100"
              style={{ objectFit: "cover", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
