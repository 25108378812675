import React, { useState } from "react";
import "../assets/css/contact.css";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import banner from "../assets/img/imresizer-1686256954473.webp";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const [applyinput, setApply] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    country: "contact",
    error_list: [],
  });
  const handleInput = (e) => {
    e.persist();
    setApply({ ...applyinput, [e.target.name]: e.target.value });
  };

  const applySubmit = (e) => {
    e.preventDefault();
    const data = {
      name: applyinput.name,
      email: applyinput.email,
      number: applyinput.number,
      message: applyinput.message,
      country: applyinput.country,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/add-apply`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success", res.data.message, "success");
          navigate("/contact");
        } else {
          setApply({ ...applyinput, error_list: res.data.validation_errors });
        }
      });
    });
  };
  return (
    <>
      <Helmet>
        <title>Contact Page</title>
        <meta name="description" content="Free Web tutorials" />
        <meta name="keywords" content="HTML, CSS, JavaScript" />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
          integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />

        <script src="/js/js/main.js" type="text/javascript" />
      </Helmet>
      <section
        className="page_breadcrumbs ds color parallax about_us section_padding_top"
        style={{ backgroundImage: `url(${banner})`, height: "404px" }}
      >
        <div
          className="container"
          style={{ zIndex: "1", position: "relative" }}
        >
          <div className="row">
            <div className="col-sm-12">
              <h2 className="heading text-light pb-2">Contact Us</h2>
              <ol className="breadcrumb highlightlinks">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active fw-bold text-white">Contact </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="ovelay_1"></div>
      </section>

      <section>
        <div className="container">
          <form
            onSubmit={applySubmit}
            style={{ boxShadow: "0 0 30px rgba(0, 0, 0, 0.08)" }}
          >
            <div className="row px-5 p-2">
              <div className="col-md-3">
                <div className="row justify-content-center pb-5">
                  <div className="col-lg-12 p-3 px-4 mb-2">
                    <div>
                      <i className="bi bi-map fs-2 text-danger p-2"></i>
                      <span className="fs-6 fw-bold"> Location</span>
                    </div>
                    <div className="fw-bold text-danger">Main Branch</div>
                    <div>
                      G-7 1 st Floor, Abul Fazal Part - II Jamia Nagar, New
                      Delhi - 110025
                    </div>
                    <hr />
                    <div className="fw-bold text-danger">Corporate Branch</div>
                    <div>
                      Manglour Chowki, Deoband, Saharanpur uttar pradesh -
                      247001
                    </div>
                  </div>

                  <div className="col-lg-12  p-3 mb-2">
                    <div>
                      <i className="bi bi-telephone-fill fs-4 text-danger p-2"></i>
                      <span className="fs-6 fw-bold"> Make a Call</span>
                    </div>

                    <div className="px-4">
                      <a href="tel:+91-7505979566" className="text-dark">
                        <span>+91-7505979566</span>
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-12 p-3 mb-2">
                    <div>
                      <i className="bi bi-envelope-open-fill fs-4 text-danger p-2"></i>
                      <span className="fs-6 fw-bold"> Send a Mail</span>
                    </div>
                    <div className="px-4">
                      <a
                        href="mailto:aismdeoband@gmail.com"
                        className="text-dark"
                      >
                        {" "}
                        <span>aismdeoband@gmail.com</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-lg-4">
                  <div className="row p-lg-4 ">
                    <div className="col-lg-12   col-sm-6 col-md-6 mb-4">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={applyinput.name}
                        className="form-control"
                        required=""
                      />
                      <span className="text-danger">
                        {applyinput.error_list.name}
                      </span>
                    </div>
                    <div className="col-lg-6 col-sm-6 mb-4">
                      <label>Contact Number</label>
                      <input
                        type="number"
                        name="number"
                        onChange={handleInput}
                        value={applyinput.number}
                        className="form-control"
                        required=""
                      />
                      <span className="text-danger">
                        {applyinput.error_list.number}
                      </span>
                    </div>

                    <div className="col-lg-6 mb-4  col-sm-6">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInput}
                        value={applyinput.email}
                        className="form-control"
                        required=""
                      />
                      <span className="text-danger">
                        {applyinput.error_list.email}
                      </span>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <label>Message</label>
                      <textarea
                        name="message"
                        onChange={handleInput}
                        value={applyinput.message}
                        cols="30"
                        rows="4"
                        className="form-control"
                        required=""
                      >
                        {" "}
                      </textarea>
                      <span className="text-danger">
                        {applyinput.error_list.message}
                      </span>
                    </div>

                    <div className="col-lg-12 mb-4 mt-3">
                      <div className="link-box">
                        <button
                          className="theme-btn btn-style-one border border-0 p-3 bg-primary text-light rounded"
                          type="submit"
                        >
                          <i className="bi bi-send-fill"></i>
                          <span className="btn-title"> Send message </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="container-fluid">
          <div className="pt-5 pb-3">
            <h4>Map Location</h4>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1752.4394236333055!2d77.30040163885624!3d28.54336105013184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce41c44e268b1%3A0xf25d11a93d255886!2sAISM%20An%20institute%20for%20safety%20professional!5e0!3m2!1sen!2sin!4v1686944101861!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowfullscreen
            title="Corporate Branch Address"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Contact;
