import React from "react";
import slide from "../assets/img/slide/imresizer-1686336985721.webp";
import slide2 from "../assets/img/slide/imresizer-1686336796597.webp";
import slide3 from "../assets/img/slide/imresizer-1686337684201.webp";

const Slider = () => {
  return (
    <>
      <section id="hero">
        <div className="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval="5000"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <ol
              className="carousel-indicators"
              id="hero-carousel-indicators"
            ></ol>
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item item active"
                style={{ backgroundImage: `url(${slide})` }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      <span>Lead Auditor</span> Courses
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      AISM DEOABND is a leading construction safety Training
                      Institute dedicated to providing comprehensive safety
                      solutions to the construction industry. With a team of
                      highly trained safety professionals and a deep
                      understanding of the unique challenges faced in
                      construction, we deliver customized safety programs and
                      consulting services that prioritize the well-being of your
                      workforce.
                    </p>
                    <div>
                      <a
                        href="/our-courses"
                        className="btn-menu animate__animated animate__fadeInUp scrollto"
                      >
                        View Course
                      </a>
                      <a
                        href="#book-a-table"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn-book animate__animated animate__fadeInUp scrollto"
                      >
                        Enquiry
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="carousel-item item"
                style={{ backgroundImage: `url(${slide2})` }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      IOSH Courses
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      It is for staff in a line management, supervisory or team
                      leader role in any size of organisation in any sector.
                    </p>
                    <div>
                      <a
                        href="/our-courses"
                        className="btn-menu animate__animated animate__fadeInUp scrollto"
                      >
                        View Course
                      </a>
                      <a
                        href="#book-a-table"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn-book animate__animated animate__fadeInUp scrollto"
                      >
                        Enquiry
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="carousel-item item"
                style={{ backgroundImage: `url(${slide3})` }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      Diploma Courses
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <div>
                      <a
                        href="/our-courses"
                        className="btn-menu animate__animated animate__fadeInUp scrollto"
                      >
                        View Course
                      </a>
                      <a
                        href="#book-a-table"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn-book animate__animated animate__fadeInUp scrollto"
                      >
                        Enquiry
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </a>

            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
