import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';

const Form = () => {
 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  
  const [applyinput, setApply] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    country: "",
    error_list: [],
  });

  
  const handleInput = (e) => {
    e.persist();
    setApply({ ...applyinput, [e.target.name]: e.target.value });
  };

  const applySubmit = (e) => {
    e.preventDefault();
    const data = {
      name: applyinput.name,
      email: applyinput.email,
      number: applyinput.number,
      message: applyinput.message,
      country: applyinput.country,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/add-apply`, data).then((res) => {
        if (res.data.status === 200) {
          setShow(false);
          navigate("/thank-you", {replace: true});
        } else {
          setApply({ ...applyinput, error_list: res.data.validation_errors });
        }
      });
    });
    
  };
  return (
    <>
<button className="book-a-table-btn scrollto border-0" onClick={handleShow}>Enquiry Now</button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton style={{ background: "#be2026" }}>
          <Modal.Title className="text-white h2">CONTACT US</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <p style={{ fontSize: "20px" }}>
                To ensure that you are a trade professional, your information
                will be verified by our team.
              </p>
              <form className="pt-4" onSubmit={applySubmit} >
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 mb-4">
                    <span className="text-danger">
                      {applyinput.error_list.name}
                    </span>
                    <input
                      name="name"
                      onChange={handleInput}
                      value={applyinput.name}
                      type="text"
                      className="form-control input-lg"
                      placeholder="Name.."
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mb-4">
                    <span className="text-danger">
                      {applyinput.error_list.number}
                    </span>
                    <input
                      type="tel"
                      name="number"
                      onChange={handleInput}
                      value={applyinput.number}
                      placeholder="Phone number.."
                      className="form-control input-lg"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mb-4">
                    <span className="text-danger">
                      {applyinput.error_list.email}
                    </span>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={applyinput.email}
                      placeholder="Email.."
                      className="form-control input-lg"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mb-4">
                    <span className="text-danger">
                      {applyinput.error_list.country}
                    </span>
                    <input
                      type="text"
                      name="country"
                      onChange={handleInput}
                      value={applyinput.country}
                      placeholder="Course.."
                      className="form-control input-lg"
                    />
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mb-4">
                    <span className="text-danger">
                      {applyinput.error_list.message}
                    </span>
                    <textarea
                      name="message"
                      onChange={handleInput}
                      value={applyinput.message}
                      className="validate form-control"
                      data-prompt-position="topLeft"
                      rows="8"
                      cols="80"
                      placeholder="Type Your Message here."
                    ></textarea>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-3 text-start">
                    <button
                      type="submit"
                      className="p-2 fw-bold text-white border-0 rounded px-4"
                      style={{ background: "#be2026" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
        </Modal.Body>
        
      </Modal>  
    </>
  );
};

export default Form;
